import gql from "graphql-tag";

export const GET_LAB_ALL_PARAMETERS_M = gql`
  mutation getLabAllParametersM($abId: ID!, $labId: ID!) {
    getLabAllParametersM(abId: $abId, labId: $labId) {
      abParamId
      program
      method
      methodCode
      revision
      revisionDate
      analyte
      analyteCode
      matrix
      current
    }
  }
`;

export const GET_LABID_BY_EPA_NUMBER_M = gql`
  mutation getLabIdByEpaNumberM($abId: ID!, $epaNumber: String!) {
    getLabIdByEpaNumberM(abId: $abId, epaNumber: $epaNumber)
  }
`;

export const GET_LAB_BY_EPA_NUMBER_M = gql`
  mutation getLabByEpaNumberM($abId: ID!, $epaNumber: String!) {
    getLabByEpaNumberM(abId: $abId, epaNumber: $epaNumber) {
      labIdNo
      epaNumber
      name
      labContacts {
        firstName
        lastName
        email
        phone
        positionId
        position
        nameTitle
        primary
        active
      }
      labLocations {
        locationName
        address1
        city
        state
        zip
      }
    }
  }
`;

export const GET_LAB_BY_ID_M = gql`
  mutation getLabByIdM($abId: ID!, $labId: Int!) {
    getLabByIdM(abId: $abId, labId: $labId) {
      labIdNo
      epaNumber
      name
      labContacts {
        firstName
        lastName
        email
        phone
        positionId
        position
        nameTitle
        primary
        active
      }
      labLocations {
        locationName
        address1
        address2
        city
        state
        zip
      }
    }
  }
`;

export const UPDATE_STAGED_APPLICATION = gql`
  mutation updateStagedApplication(
    $abId: ID!
    $labId: ID!
    $abParamId: Int!
    $newValue: Int!
  ) {
    updateStagedApplication(
      abId: $abId
      labId: $labId
      abParamId: $abParamId
      newValue: $newValue
    )
  }
`;

export const STAGED_APPLICATION_ADD = gql`
  mutation stagedApplicationAdd($abId: ID!, $labId: ID!, $parameters: [ID!]!) {
    stagedApplicationAdd(abId: $abId, labId: $labId, parameters: $parameters) {
      result
      messages {
        type
        text
      }
    }
  }
`;

export const STAGED_APPLICATION_DELETE = gql`
  mutation stagedApplicationDelete(
    $abId: ID!
    $labId: ID!
    $parameters: [ID!]!
  ) {
    stagedApplicationDelete(
      abId: $abId
      labId: $labId
      parameters: $parameters
    ) {
      result
      messages {
        type
        text
      }
    }
  }
`;

export const STAGE_LAB_APPLICATION = gql`
  mutation stageLabApplication(
    $abId: ID!
    $labId: ID!
    $epaNumber: String!
    $fileData: String
    $fileJson: String
    $fileName: String
    $fileSize: Int
  ) {
    stageLabApplication(
      abId: $abId
      labId: $labId
      epaNumber: $epaNumber
      fileData: $fileData
      fileJson: $fileJson
      fileName: $fileName
      fileSize: $fileSize
    ) {
      resultCount
      messages {
        type
        text
      }
    }
  }
`;

export const STAGE_LAB_APPLICATION_XLS = gql`
  mutation stageLabApplicationXls(
    $abId: ID!
    $labId: ID!
    $epaNumber: String!
    $labPhone: String
    $pabName: String
    $labTypeName: String
    $labCode: String
    $nelapId: String
    $accountNumber: String
    $hoursOfOperation: String
    $website: String
    $publicLab: Boolean
    $fileJson: String
    $fileName: String
    $fileSize: Int
  ) {
    stageLabApplicationXls(
      abId: $abId
      labId: $labId
      epaNumber: $epaNumber
      labPhone: $labPhone
      pabName: $pabName
      labTypeName: $labTypeName
      labCode: $labCode
      nelapId: $nelapId
      accountNumber: $accountNumber
      hoursOfOperation: $hoursOfOperation
      website: $website
      publicLab: $publicLab
      fileJson: $fileJson
      fileName: $fileName
      fileSize: $fileSize
    ) {
      resultCount
      messages {
        type
        text
      }
    }
  }
`;

export const CLEAR_STAGED_APPLICATION = gql`
  mutation clearStagedApplication($abId: ID!) {
    clearStagedApplication(abId: $abId)
  }
`;

export const EXPORT_LAB_APPLICATION = gql`
  mutation exportLabApplication($abId: ID!, $labId: ID!) {
    exportLabApplication(abId: $abId, labId: $labId) {
      selected
      abParamId
      programId
      program
      methodCode
      method
      analyteCode
      analyte
      matrixId
      matrix
      current
    }
  }
`;

export const DELETE_PT_STAGED_ROW = gql`
  mutation deletePtStagedRow($abId: ID!, $_id: ID!) {
    deletePtStagedRow(abId: $abId, _id: $_id)
  }
`;

export const UPDATE_PT_STAGED_ROW = gql`
  mutation updatePtStagedRow(
    $abId: ID!
    $_id: ID!
    $studyType: String
    $studyNumber: String
    $studyShipDate: String
    $studyCloseDate: String
    $studyAmendDate: String
    $analyteCode: String
    $methodCode: String
    $resultName: String
    $labCode: String
    $providerCode: String
    $matrixName: String
  ) {
    updatePtStagedRow(
      abId: $abId
      _id: $_id
      studyType: $studyType
      studyNumber: $studyNumber
      studyShipDate: $studyShipDate
      studyCloseDate: $studyCloseDate
      studyAmendDate: $studyAmendDate
      analyteCode: $analyteCode
      methodCode: $methodCode
      resultName: $resultName
      labCode: $labCode
      providerCode: $providerCode
      matrixName: $matrixName
    )
  }
`;

export const CLEAR_PT_STAGE = gql`
  mutation clearPtStage($abId: ID!, $errorRows: [errorRowsInput!]!) {
    clearPtStage(abId: $abId, errorRows: $errorRows)
  }
`;

export const VALIDATE_STAGED_PT_DATA = gql`
  mutation validateStagedPtData(
    $abId: ID!
    $fileName: String!
    $fileSize: Int!
  ) {
    validateStagedPtData(abId: $abId, fileName: $fileName, fileSize: $fileSize)
  }
`;

export const STAGE_PT_DATA = gql`
  mutation stagePtData(
    $abId: ID!
    $fileData: String!
    $fileName: String!
    $fileSize: Int!
    $utahFormat: Boolean
  ) {
    stagePtData(
      abId: $abId
      fileData: $fileData
      fileName: $fileName
      fileSize: $fileSize
      utahFormat: $utahFormat
    ) {
      stagedCount
      messages {
        type
        text
      }
    }
  }
`;

export const CREATE_AB_ANALYTE = gql`
  mutation createAbAnalyte(
    $abId: Int!
    $analyte: String!
    $abCode: String!
    $casNumber: String!
    $type: String!
    $active: Int!
  ) {
    createAbAnalyte(
      abId: $abId
      analyte: $analyte
      abCode: $abCode
      casNumber: $casNumber
      type: $type
      active: $active
    )
  }
`;

export const UPDATE_AB_ANALYTE = gql`
  mutation updateAbAnalyte(
    $id: ID!
    $analyte: String!
    $abCode: String!
    $casNumber: String!
    $type: String!
    $active: Int!
  ) {
    updateAbAnalyte(
      id: $id
      analyte: $analyte
      abCode: $abCode
      casNumber: $casNumber
      type: $type
      active: $active
    )
  }
`;

export const REMOVE_AB_ANALYTE = gql`
  mutation removeAbAnalyte($id: ID!) {
    removeAbAnalyte(id: $id)
  }
`;

export const CREATE_AB_METHOD = gql`
  mutation createAbMethod(
    $abId: Int!
    $method: String!
    $abCode: String!
    $name: String!
    $technology: Int!
    $title: String!
    $active: Int!
  ) {
    createAbMethod(
      abId: $abId
      method: $method
      abCode: $abCode
      name: $name
      technology: $technology
      title: $title
      active: $active
    )
  }
`;

export const UPDATE_AB_METHOD = gql`
  mutation updateAbMethod(
    $id: ID!
    $method: String!
    $abCode: String!
    $name: String!
    $technology: Int!
    $title: String!
    $active: Int!
  ) {
    updateAbMethod(
      id: $id
      method: $method
      abCode: $abCode
      name: $name
      technology: $technology
      title: $title
      active: $active
    )
  }
`;

export const REMOVE_AB_METHOD = gql`
  mutation removeAbMethod($id: ID!) {
    removeAbMethod(id: $id)
  }
`;

export const ADD_AB_PARTICIPANTS = gql`
  mutation addAbParticipants(
    $abId: ID!
    $labId: ID!
    $auditNo: ID!
    $participants: [ID!]!
    $notes: String
    $atOpening: Boolean
    $atClosing: Boolean
  ) {
    addAbParticipants(
      abId: $abId
      labId: $labId
      auditNo: $auditNo
      participants: $participants
      notes: $notes
      atOpening: $atOpening
      atClosing: $atClosing
    )
  }
`;

export const ADD_LAB_PARTICIPANTS = gql`
  mutation addLabParticipants(
    $abId: ID!
    $labId: ID!
    $auditNo: ID!
    $participants: [ID!]!
    $notes: String
    $atOpening: Boolean
    $atClosing: Boolean
  ) {
    addLabParticipants(
      abId: $abId
      labId: $labId
      auditNo: $auditNo
      participants: $participants
      notes: $notes
      atOpening: $atOpening
      atClosing: $atClosing
    )
  }
`;

export const UPDATE_AB_PARTICIPANT = gql`
  mutation updateAbParticipant(
    $abId: ID!
    $auditNo: ID!
    $participantId: ID!
    $notes: String
    $atOpening: Boolean
    $atClosing: Boolean
  ) {
    updateAbParticipant(
      abId: $abId
      auditNo: $auditNo
      participantId: $participantId
      notes: $notes
      atOpening: $atOpening
      atClosing: $atClosing
    )
  }
`;

export const UPDATE_AB_PARTICIPANT_FIELD = gql`
  mutation updateAbParticipantField(
    $abId: ID!
    $labId: ID!
    $auditNo: ID!
    $participantId: ID!
    $field: String!
    $value: String!
    $source: String!
  ) {
    updateAbParticipantField(
      abId: $abId
      labId: $labId
      auditNo: $auditNo
      participantId: $participantId
      field: $field
      value: $value
      source: $source
    )
  }
`;

export const REMOVE_AB_PARTICIPANT = gql`
  mutation removeAbParticipant(
    $abId: ID!
    $labId: ID!
    $auditNo: ID!
    $participantId: ID!
    $source: String!
  ) {
    removeAbParticipant(
      abId: $abId
      labId: $labId
      auditNo: $auditNo
      participantId: $participantId
      source: $source
    )
  }
`;

export const CREATE_AUDIT = gql`
  mutation createAudit(
    $abId: ID!
    $labId: ID!
    $title: String!
    $auditType: Int
    $auditDueBy: String
  ) {
    createAudit(
      abId: $abId
      labId: $labId
      title: $title
      auditType: $auditType
      auditDueBy: $auditDueBy
    )
  }
`;

export const CHECK_VALID_USER_EMAIL_M = gql`
  mutation checkValidUserEmailM(
    $abId: ID!
    $email: String!
    $existingUserId: String
  ) {
    checkValidUserEmailM(
      abId: $abId
      email: $email
      existingUserId: $existingUserId
    )
  }
`;

export const CREATE_AUDIT_TYPE = gql`
  mutation createAuditType(
    $abId: ID!
    $auditType: String!
    $description: String
    $auditTypeFreq: Int
  ) {
    createAuditType(
      abId: $abId
      auditType: $auditType
      description: $description
      auditTypeFreq: $auditTypeFreq
    ) {
      _id
    }
  }
`;

export const UPDATE_AUDIT_TYPE = gql`
  mutation updateAuditType(
    $abId: ID!
    $_id: ID!
    $auditType: String!
    $description: String
    $auditTypeFreq: Int
    $milestones: [TypeMilestoneInput!]!
  ) {
    updateAuditType(
      abId: $abId
      _id: $_id
      auditType: $auditType
      description: $description
      auditTypeFreq: $auditTypeFreq
      milestones: $milestones
    )
  }
`;

export const REMOVE_AUDIT_TYPE = gql`
  mutation removeAuditType($_id: ID!) {
    removeAuditType(_id: $_id)
  }
`;

export const REMOVE_AUDIT_PDF = gql`
  mutation removeAuditPdf($abId: ID!, $labId: ID!, $auditNo: ID!, $_id: ID!) {
    removeAuditPdf(abId: $abId, labId: $labId, auditNo: $auditNo, _id: $_id)
  }
`;

export const CREATE_AUDIT_PDF = gql`
  mutation createAuditPdf(
    $abId: ID!
    $labId: ID!
    $auditNo: ID!
    $createDate: String!
    $issueDate: String
    $issuedBy: Int
    $notes: String
    $base64pdf: String!
  ) {
    createAuditPdf(
      abId: $abId
      labId: $labId
      auditNo: $auditNo
      createDate: $createDate
      issueDate: $issueDate
      issuedBy: $issuedBy
      notes: $notes
      base64pdf: $base64pdf
    )
  }
`;

export const UPDATE_REPORT_DEF_DETAIL = gql`
  mutation updateReportDefDetail(
    $abId: ID!
    $rptHdrId: ID!
    $text1Name: String
    $text1Json: String
    $text2Name: String
    $text2Json: String
    $text3Name: String
    $text3Json: String
    $image1Name: String
    $image1Json: String
    $image1Data: String
    $image2Name: String
    $image2Json: String
    $image2Data: String
    $image3Name: String
    $image3Json: String
    $image3Data: String
    $useRptChk: Boolean!
    $reportType: String!
    $reportTitle: String!
    $reportDescription: String!
  ) {
    updateReportDefDetail(
      abId: $abId
      rptHdrId: $rptHdrId
      text1Name: $text1Name
      text1Json: $text1Json
      text2Name: $text2Name
      text2Json: $text2Json
      text3Name: $text3Name
      text3Json: $text3Json
      image1Name: $image1Name
      image1Json: $image1Json
      image1Data: $image1Data
      image2Name: $image2Name
      image2Json: $image2Json
      image2Data: $image2Data
      image3Name: $image3Name
      image3Json: $image3Json
      image3Data: $image3Data
      useRptChk: $useRptChk
      reportType: $reportType
      reportTitle: $reportTitle
      reportDescription: $reportDescription
    )
  }
`;

export const GET_REPORT_DEF_M = gql`
  mutation getReportDefM($abId: ID!, $rptHdrId: ID!, $reportType: String!) {
    getReportDefM(abId: $abId, rptHdrId: $rptHdrId, reportType: $reportType) {
      _id
      text1Name
      text1Json
      text2Name
      text2Json
      text3Name
      text3Json
      image1Name
      image1Json
      image1Data
      image2Name
      image2Json
      image2Data
      image3Name
      image3Json
      image3Data
      rptHdrId
      source
    }
  }
`;

export const GET_LAMS_DEMOGRAPHICS_M = gql`
  mutation getLamsDemographicsM($abId: ID!) {
    getLamsDemographicsM(abId: $abId) {
      tniLabCode
      epaLabCode
      stateId
      labName
      address1
      address2
      city
      state
      zip
      country
      phone
      fax
      contactFrstName
      contactLastName
      contactEmail
      primaryAb
      primaryAbCode
      commercialSamples
      labType
    }
  }
`;

export const GET_LAMS_PARAMETERS_M = gql`
  mutation getLamsParametersM($abId: ID!) {
    getLamsParametersM(abId: $abId) {
      tniLabCode
      matrix
      methodCode
      analyteCode
      maxDate
      status
      type
      expirationDate
    }
  }
`;

export const GET_PT_DATE_EXEPTIONS_M = gql`
  mutation getPTDateExeptionsM($abId: ID!, $days: Int!) {
    getPTDateExeptionsM(abId: $abId, days: $days) {
      labId
      lab
      analyteCode
      analyte
      matrixId
      matrix
      technology
      studyNumber1
      studyNumber2
      studyNumber3
      studyType1
      studyType2
      studyType3
      studyCloseDate1
      studyCloseDate2
      studyCloseDate3
      studyShipDate1
      studyShipDate2
      studyShipDate3
      close1ToShip2
      close2ToShip3
    }
  }
`;

export const UPDATE_AB_IMAGE = gql`
  mutation updateAbImage($abId: ID!, $hdrImageId: Int!) {
    updateAbImage(abId: $abId, hdrImageId: $hdrImageId)
  }
`;

export const CREATE_SNAPSHOT_PDF = gql`
  mutation createSnapshotPdf(
    $abId: ID!
    $labId: ID!
    $snapshotId: ID!
    $createDate: String!
    $base64pdf: String!
  ) {
    createSnapshotPdf(
      abId: $abId
      labId: $labId
      snapshotId: $snapshotId
      createDate: $createDate
      base64pdf: $base64pdf
    )
  }
`;

export const GET_SNAPSHOT_PDF_M = gql`
  mutation getSnapshotPdfM($abId: ID!, $labId: ID!, $snapshotId: ID!) {
    getSnapshotPdfM(abId: $abId, labId: $labId, snapshotId: $snapshotId) {
      createDate
      base64pdf
    }
  }
`;

export const GET_LAST_SNAPSHOT_PDF_M = gql`
  mutation getLastSnapshotPdfM($abId: ID!, $labId: ID!) {
    getLastSnapshotPdfM(abId: $abId, labId: $labId) {
      createDate
      base64pdf
    }
  }
`;

export const UPLOAD_IMAGE_AB = gql`
  mutation uploadImageAb(
    $abId: ID!
    $imageType: Int!
    $fileName: String!
    $fileBase64: String!
  ) {
    uploadImageAb(
      abId: $abId
      imageType: $imageType
      fileName: $fileName
      fileBase64: $fileBase64
    )
  }
`;

export const GET_PARAM_LABS_M = gql`
  mutation abParamLabsM($abId: ID!, $abParamId: ID!) {
    abParamLabsM(abId: $abId, abParamId: $abParamId) {
      labId
      name
      epaNumber
      labCode
      address1
      city
      state
      zip
    }
  }
`;

export const GET_PT_RESULT_METHODS_M = gql`
  mutation getPtResultMethodsM(
    $abId: ID!
    $labId: ID!
    $providerId: ID!
    $studyType: String!
    $studyNumber: String!
    $studyShipDate: String!
    $studyCloseDate: String!
    $analyteCode: Int!
    $matrixId: Int!
    $technology: String!
  ) {
    getPtResultMethodsM(
      abId: $abId
      labId: $labId
      providerId: $providerId
      studyType: $studyType
      studyNumber: $studyNumber
      studyShipDate: $studyShipDate
      studyCloseDate: $studyCloseDate
      analyteCode: $analyteCode
      matrixId: $matrixId
      technology: $technology
    ) {
      methodCode
      methodName
      resultId
      processDate
      fileName
    }
  }
`;

export const GET_PT_RESULTS_M = gql`
  mutation getPtResultsM($abId: ID!, $labId: ID!, $years: Int!) {
    getPtResultsM(abId: $abId, labId: $labId, years: $years) {
      _id
      labIdNo
      labName
      providerId
      providerName
      studyType
      studyNumber
      studyShipDate
      studyCloseDate
      analyteCode
      analyte
      methodCode
      method
      technology
      matrixId
      matrix
      resultId
      resultName
      fileName
      fileSize
      processDate
      firstName
      lastName
    }
  }
`;

export const GET_PT_RESULTS_LABS_M = gql`
  mutation getPtResultsLabsM($abId: ID!, $ptResIds: [ID!]!) {
    getPtResultsLabsM(abId: $abId, ptResIds: $ptResIds)
  }
`;

export const GET_CERTIFICATE_RPT_M = gql`
  mutation certificateRptM($abId: ID!, $labId: ID!, $_id: ID) {
    certificateRptM(abId: $abId, labId: $labId, _id: $_id) {
      abCode
      abName
      abLongName
      useProgram
      labName
      epaNumber
      labCode
      fieldLab
      accredStart
      accredEnd
      address
      city
      state
      zip
      issueDate
      issuedBy
      issuedByName
      issuedByTitle
      hdrImage
      certSuppInfo
      certDetail {
        matrixName
        matrixShortName
        matrixCode
        programName
        programCode
        methodName
        methodCode
        revision
        revisionDate
        technology
        analyteName
        analyteCode
        startDate
        endDate
        primaryAb
        primaryAbName
        primaryAbCode
        abParamId
        notes
        approved
        approvedText
      }
      params {
        matrixName
        programName
        methods {
          name
          methodCode
          analytes {
            name
            analyteCode
            startDate
            endDate
            primaryAb
          }
        }
      }
      rptDef {
        text1Name
        text1Json
        image1Name
        image1Json
        image1Data
        text2Name
        text2Json
        image2Name
        image2Json
        image2Data
        text3Name
        text3Json
        image3Name
        image3Json
        image3Data
      }
      reportName
      documentId
    }
  }
`;

export const GET_CHECKLIST_M = gql`
  mutation getChecklistM(
    $abId: ID!
    $labId: ID!
    $auditNo: ID
    $checklistId: ID!
  ) {
    getChecklistM(
      abId: $abId
      labId: $labId
      auditNo: $auditNo
      checklistId: $checklistId
    ) {
      checklistId
      title
      createDate
      enteredBy
      enteredByName
      methodCode
      methodName
      notes
      templateName
      checklistDetail {
        _id
        sequence
        type
        typeId
        text
        reference
        requirement
        textResponse
        questionResponse
        typeOption
        notes
        checklistMedia {
          _id
          dtlSequence
          createDate
          fileName
          fileType
          fileSize
          base64File
        }
      }
    }
  }
`;

export const GET_AUDIT_RPT_M = gql`
  mutation auditRptM(
    $abId: ID!
    $labId: ID!
    $auditNo: ID!
    $issueDate: String!
    $issuedBy: ID!
  ) {
    auditRptM(
      abId: $abId
      labId: $labId
      auditNo: $auditNo
      issueDate: $issueDate
      issuedBy: $issuedBy
    ) {
      abName
      longName
      labName
      contactName
      contactTitle
      address1
      city
      state
      zip
      printDate
      auditStartDate
      auditEndDate
      auditCompleteDate
      leadAssessor
      assessorTitle
      assessorEmail
      headerImage
      footerImage
      documentId
      auditTitle
      auditType
      issueDate
      issuedByName
      issuedByEmail
      reportData {
        section1Json
        section2Json
        section3Json
        headerImage
        footerImage
      }
      abParticipants {
        participantId
        firstName
        lastName
      }
      labParticipants {
        participantId
        firstName
        lastName
      }
      findings {
        reference
        methodName
        requirement
        comments
      }
      recommendations {
        reference
        methodName
        requirement
        comments
      }
      rptDef {
        text1Name
        text1Json
        image1Name
        image1Json
        image1Data
        text2Name
        text2Json
        image2Name
        image2Json
        image2Data
        text3Name
        text3Json
        image3Name
        image3Json
        image3Data
      }
    }
  }
`;

export const TEST1 = gql`
  mutation Test1($abId: ID!, $methodFrom: String!, $methodTo: String!) {
    Test1(abId: $abId, methodFrom: $methodFrom, methodTo: $methodTo)
  }
`;

export const IMPORT_CHECKLIST_FINDINGS = gql`
  mutation ImportChecklistFindings($abId: ID!, $labId: ID!, $auditNo: ID!) {
    ImportChecklistFindings(abId: $abId, labId: $labId, auditNo: $auditNo)
  }
`;

export const UPDATE_TEMPLATE = gql`
  mutation UpdateTemplate(
    $abId: ID!
    $hdrId: ID!
    $title: String!
    $notes: String
    $active: Int!
  ) {
    UpdateTemplate(
      abId: $abId
      hdrId: $hdrId
      title: $title
      notes: $notes
      active: $active
    )
  }
`;

export const UPDATE_TEMPLATE_ITEM = gql`
  mutation UpdateTemplateItem(
    $abId: ID!
    $hdrId: ID!
    $itemId: ID!
    $sequence: Int!
    $sequenceOld: Int!
    $typeId: Int!
    $itemText: String!
    $typeOption: String
  ) {
    UpdateTemplateItem(
      abId: $abId
      hdrId: $hdrId
      itemId: $itemId
      sequence: $sequence
      sequenceOld: $sequenceOld
      typeId: $typeId
      itemText: $itemText
      typeOption: $typeOption
    )
  }
`;

export const DELETE_TEMPLATE = gql`
  mutation deleteTemplate($abId: ID!, $templateId: Int!) {
    deleteTemplate(abId: $abId, templateId: $templateId)
  }
`;

export const IMPORT_TEMPLATE_FILE_DATA = gql`
  mutation importTemplateFileData($abId: ID!, $fileData: String!) {
    importTemplateFileData(abId: $abId, fileData: $fileData)
  }
`;

export const REMOVE_CHECKLIST_DEFINITION = gql`
  mutation RemoveChecklistDefinition(
    $abId: ID!
    $_id: ID!
    $checklistId: ID!
    $labId: ID!
    $auditNo: ID!
  ) {
    RemoveChecklistDefinition(
      abId: $abId
      _id: $_id
      checklistId: $checklistId
      labId: $labId
      auditNo: $auditNo
    )
  }
`;

export const REMOVE_CHECKLIST = gql`
  mutation RemoveChecklist(
    $abId: ID!
    $_id: ID!
    $checklistId: ID!
    $labId: ID!
    $auditNo: ID!
  ) {
    RemoveChecklist(
      abId: $abId
      _id: $_id
      checklistId: $checklistId
      labId: $labId
      auditNo: $auditNo
    )
  }
`;

export const UPDATE_CHECKLIST_STR_HEADER = gql`
  mutation UpdateChecklistStrHeader(
    $abId: ID!
    $_id: ID!
    $field: String!
    $value: String!
  ) {
    UpdateChecklistStrHeader(
      abId: $abId
      _id: $_id
      field: $field
      value: $value
    )
  }
`;

export const UPDATE_CHECKLIST_INT_DETAIL = gql`
  mutation UpdateChecklistIntDetail(
    $abId: ID!
    $checklistId: ID!
    $_id: ID!
    $field: String!
    $value: Int!
  ) {
    UpdateChecklistIntDetail(
      abId: $abId
      checklistId: $checklistId
      _id: $_id
      field: $field
      value: $value
    )
  }
`;

export const UPDATE_CHECKLIST_STR_DETAIL = gql`
  mutation UpdateChecklistStrDetail(
    $abId: ID!
    $checklistId: ID!
    $_id: ID!
    $field: String!
    $value: String!
  ) {
    UpdateChecklistStrDetail(
      abId: $abId
      checklistId: $checklistId
      _id: $_id
      field: $field
      value: $value
    )
  }
`;

export const CREATE_CHECKLIST = gql`
  mutation CreateChecklist(
    $_id: ID!
    $abId: ID!
    $labId: ID!
    $auditNo: ID!
    $templateId: ID!
    $templateName: String!
    $title: String!
  ) {
    CreateChecklist(
      _id: $_id
      abId: $abId
      labId: $labId
      auditNo: $auditNo
      templateId: $templateId
      templateName: $templateName
      title: $title
    )
  }
`;

export const IMPORT_CHECKLIST_METHODS = gql`
  mutation ImportChecklistMethods($abId: ID!, $labId: ID!, $auditNo: ID!) {
    ImportChecklistMethods(abId: $abId, labId: $labId, auditNo: $auditNo)
  }
`;

export const UPDATE_METHOD_CHECKLIST = gql`
  mutation UpdateMethodChecklist(
    $_id: ID!
    $abId: ID!
    $labId: ID!
    $auditNo: ID!
    $checklistId: ID
    $templateId: ID
    $oldTemplateId: ID
    $templateName: String
    $assignedTo: ID
  ) {
    UpdateMethodChecklist(
      _id: $_id
      abId: $abId
      labId: $labId
      auditNo: $auditNo
      checklistId: $checklistId
      templateId: $templateId
      oldTemplateId: $oldTemplateId
      templateName: $templateName
      assignedTo: $assignedTo
    )
  }
`;

export const CREATE_CHECKLIST_DEFINITION = gql`
  mutation CreateChecklistDefinition(
    $abId: ID!
    $labId: ID!
    $auditNo: ID!
    $title: String!
    $templateId: ID
    $assignedTo: ID
  ) {
    CreateChecklistDefinition(
      abId: $abId
      labId: $labId
      auditNo: $auditNo
      title: $title
      templateId: $templateId
      assignedTo: $assignedTo
    )
  }
`;

export const CREATE_ADHOC_CHECKLIST = gql`
  mutation CreateAdhocChecklist(
    $abId: ID!
    $labId: ID!
    $auditNo: ID!
    $title: String!
    $templateId: ID
    $assignedTo: ID
  ) {
    CreateAdhocChecklist(
      abId: $abId
      labId: $labId
      auditNo: $auditNo
      title: $title
      templateId: $templateId
      assignedTo: $assignedTo
    )
  }
`;

export const UPDATE_CHECKLIST_DEFINITION = gql`
  mutation UpdateChecklistDefinition(
    $_id: ID!
    $abId: ID!
    $labId: ID!
    $auditNo: ID!
    $title: String!
    $templateId: ID
    $assignedTo: ID
    $methodCode: String
    $isDefaultTemplate: Boolean!
  ) {
    UpdateChecklistDefinition(
      _id: $_id
      abId: $abId
      labId: $labId
      auditNo: $auditNo
      title: $title
      templateId: $templateId
      assignedTo: $assignedTo
      methodCode: $methodCode
      isDefaultTemplate: $isDefaultTemplate
    )
  }
`;

export const CREATE_CHECKLIST_DETAIL_MEDIA = gql`
  mutation CreateChecklistDetailMedia(
    $abId: ID!
    $labId: ID!
    $auditNo: ID!
    $hdrId: ID!
    $dtlId: ID!
    $dtlSequence: Int!
    $fileName: String!
    $fileType: String!
    $fileSize: Float!
    $base64File: String!
  ) {
    CreateChecklistDetailMedia(
      abId: $abId
      labId: $labId
      auditNo: $auditNo
      hdrId: $hdrId
      dtlId: $dtlId
      dtlSequence: $dtlSequence
      fileName: $fileName
      fileType: $fileType
      fileSize: $fileSize
      base64File: $base64File
    )
  }
`;

export const REMOVE_CHECKLIST_DETAIL_MEDIA = gql`
  mutation RemoveChecklistDetailMedia(
    $abId: ID!
    $labId: ID!
    $auditNo: ID!
    $hdrId: ID!
    $dtlId: ID!
    $dtlSequence: Int!
    $mediaId: Int!
  ) {
    RemoveChecklistDetailMedia(
      abId: $abId
      labId: $labId
      auditNo: $auditNo
      hdrId: $hdrId
      dtlId: $dtlId
      dtlSequence: $dtlSequence
      mediaId: $mediaId
    )
  }
`;

export const UPDATE_ADHOC_CHECKLIST = gql`
  mutation UpdateAdhocChecklist(
    $_id: ID!
    $abId: ID!
    $labId: ID!
    $auditNo: ID!
    $title: String!
    $templateId: ID
    $assignedTo: ID
  ) {
    UpdateAdhocChecklist(
      _id: $_id
      abId: $abId
      labId: $labId
      auditNo: $auditNo
      title: $title
      templateId: $templateId
      assignedTo: $assignedTo
    )
  }
`;

export const CREATE_AB_PARAMS = gql`
  mutation createAbParameters($abId: ID!, $params: [ParamsInput!]!) {
    createAbParameters(abId: $abId, params: $params)
  }
`;

export const CREATE_FEE = gql`
  mutation createFee(
    $abId: ID!
    $feeName: String!
    $description: String!
    $fee: String!
    $feeType: Int
    $additionalFee: Float
    $maxFee: Float
    $baseFeeNum: Int
  ) {
    createFee(
      abId: $abId
      feeName: $feeName
      description: $description
      fee: $fee
      feeType: $feeType
      additionalFee: $additionalFee
      maxFee: $maxFee
      baseFeeNum: $baseFeeNum
    ) {
      _id
    }
  }
`;

export const UPDATE_FEE = gql`
  mutation updateFee(
    $_id: ID!
    $abId: ID!
    $feeName: String!
    $description: String!
    $fee: String!
    $feeType: Int
    $additionalFee: Float
    $maxFee: Float
    $baseFeeNum: Int
  ) {
    updateFee(
      _id: $_id
      abId: $abId
      feeName: $feeName
      description: $description
      fee: $fee
      feeType: $feeType
      additionalFee: $additionalFee
      maxFee: $maxFee
      baseFeeNum: $baseFeeNum
    )
  }
`;

export const REMOVE_FEE = gql`
  mutation removeFee($_id: ID!, $abId: ID!) {
    removeFee(_id: $_id, abId: $abId)
  }
`;

export const CREATE_MILESTONE_CONFIG = gql`
  mutation createMilestoneConfig(
    $abId: ID!
    $milestoneSequence: Int!
    $milestoneName: String!
    $milestoneUserId: Int!
  ) {
    createMilestoneConfig(
      abId: $abId
      milestoneSequence: $milestoneSequence
      milestoneName: $milestoneName
      milestoneUserId: $milestoneUserId
    ) {
      _id
    }
  }
`;

export const UPDATE_MILESTONE_CONFIG = gql`
  mutation updateMilestoneConfig(
    $_id: ID!
    $abId: ID!
    $milestoneSequence: Int!
    $milestoneName: String!
    $milestoneUserId: Int!
  ) {
    updateMilestoneConfig(
      _id: $_id
      abId: $abId
      milestoneSequence: $milestoneSequence
      milestoneName: $milestoneName
      milestoneUserId: $milestoneUserId
    )
  }
`;

export const REMOVE_MILESTONE_CONFIG = gql`
  mutation removeMilestoneConfig($_id: ID!, $abId: ID!) {
    removeMilestoneConfig(_id: $_id, abId: $abId)
  }
`;

export const UPDATE_MILESTONE_DATE = gql`
  mutation updateMilestoneDate(
    $abId: ID!
    $labId: ID!
    $auditNo: ID!
    $milestoneSequence: Int!
    $completedDate: String
  ) {
    updateMilestoneDate(
      abId: $abId
      labId: $labId
      auditNo: $auditNo
      milestoneSequence: $milestoneSequence
      completedDate: $completedDate
    )
  }
`;

export const UPDATE_MILESTONE_NOTES = gql`
  mutation updatetMilestoneNotes(
    $abId: ID!
    $labId: ID!
    $auditNo: ID!
    $milestoneSequence: Int!
    $notes: String
  ) {
    updateMilestoneNotes(
      abId: $abId
      labId: $labId
      auditNo: $auditNo
      milestoneSequence: $milestoneSequence
      notes: $notes
    )
  }
`;

export const UPSERT_MILESTONE_FIELD = gql`
  mutation upsertMilestoneField(
    $abId: ID!
    $auditNo: ID!
    $fieldName: String!
    $fieldValue: String!
  ) {
    upsertMilestoneField(
      abId: $abId
      auditNo: $auditNo
      fieldName: $fieldName
      fieldValue: $fieldValue
    )
  }
`;

export const VALIDATE_PT_FILE_2 = gql`
  mutation validatePtFile2($file: Upload!, $abId: ID!) {
    validatePtFile2(file: $file, abId: $abId) {
      studyType
      studyNumber
      studyShipDate
      studyCloseDate
      studyAmendDate
      analyteCode
      analyteName
      validAnalyte
      methodCode
      methodName
      validMethod
      result
      resultId
      validResult
      laboratory
      labId
      labName
      providerCode
      providerId
      validProvider
      matrix
      matrixId
      validMatrix
    }
  }
`;

export const UPDATE_LABPARAMETER_FIELD = gql`
  mutation updateLabParameterField(
    $id: ID!
    $fieldName: String!
    $fieldValue: String!
  ) {
    updateLabParameterField(
      id: $id
      fieldName: $fieldName
      fieldValue: $fieldValue
    )
  }
`;

export const UPDATE_ABPARAMETERS = gql`
  mutation updateAbParameters(
    $abId: ID!
    $_id: [ID!]!
    $updateObj: AbParamUpdateObj!
  ) {
    updateAbParameters(abId: $abId, _id: $_id, updateObj: $updateObj)
  }
`;

export const UPDATE_ABPARAMETER = gql`
  mutation updateAbParameter($abId: ID!, $id: ID!, $feeId: ID, $active: Int) {
    updateAbParameter(abId: $abId, id: $id, feeId: $feeId, active: $active)
  }
`;

export const CREATE_LAB = gql`
  mutation createLab($abId: ID!, $name: String!) {
    createLab(abId: $abId, name: $name)
  }
`;

export const UPDATE_AB = gql`
  mutation updateAb(
    $_id: ID!
    $abName: String!
    $abLongName: String!
    $address: String!
    $lat: String!
    $lng: String!
    $useProgram: Boolean!
  ) {
    updateAb(
      _id: $_id
      name: $abName
      longName: $abLongName
      address: $address
      lat: $lat
      lng: $lng
      useProgram: $useProgram
    )
  }
`;

export const REMOVE_AUDIT = gql`
  mutation removeAudit($abId: ID!, $labId: ID!, $_id: ID!) {
    removeAudit(abId: $abId, labId: $labId, _id: $_id)
  }
`;

export const CREATE_FINAL_RESULTS = gql`
  mutation createFinalResults(
    $abId: ID!
    $labId: ID!
    $auditNo: ID!
    $results: [FinalResultFields!]!
  ) {
    createFinalResults(
      abId: $abId
      labId: $labId
      auditNo: $auditNo
      results: $results
    )
  }
`;

export const CREATE_FINAL_RESULT = gql`
  mutation createFinalResult(
    $abId: ID!
    $labId: ID!
    $auditNo: ID!
    $checklist: String
    $reference: String
    $requirement: String
    $findingType: Int
    $comments: String
    $otherNotes: String
  ) {
    createFinalResult(
      abId: $abId
      labId: $labId
      auditNo: $auditNo
      checklist: $checklist
      reference: $reference
      requirement: $requirement
      findingType: $findingType
      comments: $comments
      otherNotes: $otherNotes
    ) {
      _id
    }
  }
`;

export const UPDATE_FINAL_RESULT = gql`
  mutation updateFinalResult(
    $_id: Int!
    $checklist: String
    $reference: String
    $requirement: String
    $findingType: Int
    $comments: String
    $otherNotes: String
  ) {
    updateFinalResult(
      _id: $_id
      checklist: $checklist
      reference: $reference
      requirement: $requirement
      findingType: $findingType
      comments: $comments
      otherNotes: $otherNotes
    )
  }
`;

export const REMOVE_FINAL_RESULT = gql`
  mutation removeFinalResult($_id: ID!) {
    removeFinalResult(_id: $_id)
  }
`;

export const REMOVE_FINAL_RESULTS = gql`
  mutation removeFinalResults($abId: ID!, $_idArr: [ID!]!) {
    removeFinalResults(abId: $abId, _idArr: $_idArr)
  }
`;

export const UPDATE_TOKEN = gql`
  mutation updateToken($token: String!, $refreshToken: String!) {
    updateToken(token: $token, refreshToken: $refreshToken) {
      token
      refreshToken
    }
  }
`;

export const PROCESS_PT_SUMMARY = gql`
  mutation processPtSummary($abId: ID!, $labId: ID!) {
    processPtSummaryTransaction(abId: $abId, labId: $labId)
  }
`;

export const DELETE_PT_RESULT = gql`
  mutation deleteLabPtResult($abId: ID!, $labId: Int!) {
    deleteLabPtResult(abId: $abId, labId: $labId)
  }
`;

export const UPDATE_PT_ACTIONS = gql`
  mutation updatePtActions(
    $abId: ID!
    $sentDate: String!
    $receivedDate: String!
    $status: Int!
    $sentDateChk: Boolean!
    $receivedDateChk: Boolean!
    $statusChk: Boolean!
    $actionIds: [ID!]!
  ) {
    updatePtActions(
      abId: $abId
      sentDate: $sentDate
      receivedDate: $receivedDate
      status: $status
      sentDateChk: $sentDateChk
      receivedDateChk: $receivedDateChk
      statusChk: $statusChk
      actionIds: $actionIds
    )
  }
`;

export const GOOGLE_FILE_SAVE = gql`
  mutation googleFileSave($abId: ID!, $file: Upload!, $destination: String!) {
    googleFileSave(abId: $abId, file: $file, destination: $destination)
  }
`;

export const REMOVE_GOOGLE_IMG = gql`
  mutation removeGoogleImg($abId: ID!, $fileName: String!) {
    removeGoogleImg(abId: $abId, fileName: $fileName)
  }
`;

export const UPLOAD_GOOGLE_IMG = gql`
  mutation uploadGoogleImg($file: Upload!, $abId: ID!) {
    uploadGoogleImg(file: $file, abId: $abId)
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser(
    $id: ID!
    $firstName: String!
    $lastName: String!
    $email: String!
    $role: String!
    $assessor: Boolean!
    $title: String!
    $mobilePhone: String!
    $active: Boolean
  ) {
    updateUser(
      id: $id
      firstName: $firstName
      lastName: $lastName
      email: $email
      role: $role
      assessor: $assessor
      title: $title
      mobilePhone: $mobilePhone
      active: $active
    )
  }
`;

export const INVITE_USER = gql`
  mutation inviteUser(
    $lastName: String!
    $firstName: String!
    $email: String!
    $role: String!
    $assessor: Boolean!
    $title: String!
    $mobilePhone: String!
    $abId: ID!
    $active: Boolean
    $domain: String!
  ) {
    inviteUser(
      firstName: $firstName
      lastName: $lastName
      email: $email
      role: $role
      assessor: $assessor
      title: $title
      mobilePhone: $mobilePhone
      abId: $abId
      active: $active
      domain: $domain
    ) {
      id
    }
  }
`;

export const REMOVE_USER = gql`
  mutation removeUser($id: ID!) {
    removeUser(id: $id)
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation forgotPassword($email: String!, $domain: String!) {
    forgotPassword(email: $email, domain: $domain)
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword($userId: ID!, $token: String!, $password: String!) {
    resetPassword(userId: $userId, token: $token, password: $password)
  }
`;

export const CREATE_LAB_POSITION = gql`
  mutation createLabPosition($abId: ID!, $position: String!, $rank: Int) {
    createLabPosition(abId: $abId, position: $position, rank: $rank) {
      _id
    }
  }
`;

export const UPDATE_LAB_POSITION = gql`
  mutation updateLabPosition($_id: ID!, $position: String!, $rank: Int) {
    updateLabPosition(_id: $_id, position: $position, rank: $rank)
  }
`;

export const REMOVE_LAB_POSITION = gql`
  mutation removeLabPosition($_id: ID!) {
    removeLabPosition(_id: $_id)
  }
`;

export const CREATE_LAB_TYPE = gql`
  mutation createLabType($abId: ID!, $labType: String!) {
    createLabType(abId: $abId, labType: $labType) {
      _id
    }
  }
`;

export const REMOVE_LAB_TYPE = gql`
  mutation removeLabType($_id: ID!) {
    removeLabType(_id: $_id)
  }
`;

export const UPDATE_LAB_TYPE = gql`
  mutation updateLabType($_id: ID!, $labType: String!) {
    updateLabType(_id: $_id, labType: $labType)
  }
`;

export const UPDATE_LAB = gql`
  mutation updateLab(
    $_id: ID!
    $abId: ID!
    $labIdNo: Int!
    $name: String
    $shortName: String
    $labCode: String
    $accountNumber: String
    $epaNumber: String
    $nelapId: String
    $leadAuditor: Int
    $initialAccred: String
    $accredStart: String
    $accredEnd: String
    $taxId: String
    $primaryAb: String
    $active: Int
    $isPrimary: Int
    $isSecondary: Int
    $fieldLab: Int
    $dmrqaLab: Int
    $publicLab: Int
    $stateAccredOnly: Int
    $notes: String
    $certSuppInfo: String
    $labType: Int
    $hoursOfOperation: String
    $directionsToLab: String
    $website: String
    $labPhone: String
  ) {
    updateLab(
      _id: $_id
      abId: $abId
      labIdNo: $labIdNo
      name: $name
      shortName: $shortName
      labCode: $labCode
      accountNumber: $accountNumber
      epaNumber: $epaNumber
      nelapId: $nelapId
      leadAuditor: $leadAuditor
      initialAccred: $initialAccred
      accredStart: $accredStart
      accredEnd: $accredEnd
      taxId: $taxId
      primaryAb: $primaryAb
      active: $active
      isPrimary: $isPrimary
      isSecondary: $isSecondary
      fieldLab: $fieldLab
      dmrqaLab: $dmrqaLab
      publicLab: $publicLab
      stateAccredOnly: $stateAccredOnly
      notes: $notes
      certSuppInfo: $certSuppInfo
      labType: $labType
      hoursOfOperation: $hoursOfOperation
      directionsToLab: $directionsToLab
      website: $website
      labPhone: $labPhone
    )
  }
`;

export const UPDATE_LAB_INT_FIELD = gql`
  mutation updateLabIntField(
    $abId: ID!
    $id: ID!
    $field: String!
    $value: Int!
    $prevValue: Int
  ) {
    updateLabIntField(
      abId: $abId
      id: $id
      field: $field
      value: $value
      prevValue: $prevValue
    )
  }
`;

export const UPDATE_LAB_STRING_FIELD = gql`
  mutation updateLabStringField(
    $abId: ID!
    $id: ID!
    $field: String!
    $value: String!
    $prevValue: String
  ) {
    updateLabStringField(
      abId: $abId
      id: $id
      field: $field
      value: $value
      prevValue: $prevValue
    )
  }
`;

export const UPDATE_USER_AB = gql`
  mutation updateUserAb($id: ID!, $abId: ID!) {
    updateUserAb(id: $id, abId: $abId)
  }
`;

export const REMOVE_ISSUE = gql`
  mutation removeIssue($_id: ID!) {
    removeIssue(_id: $_id)
  }
`;

export const UPDATE_ISSUE = gql`
  mutation updateIssue(
    $_id: ID!
    $title: String!
    $issueType: Int
    $detail: String
    $richDetail: String
    $resolution: String
    $priority: Int
    $status: Int
    $dateEntered: String
    $dateDue: String
    $dateComplete: String
    $lastUpdated: String
    $enteredBy: Int
    $assignedTo: Int
  ) {
    updateIssue(
      _id: $_id
      title: $title
      issueType: $issueType
      detail: $detail
      richDetail: $richDetail
      resolution: $resolution
      priority: $priority
      status: $status
      dateEntered: $dateEntered
      dateDue: $dateDue
      dateComplete: $dateComplete
      lastUpdated: $lastUpdated
      enteredBy: $enteredBy
      assignedTo: $assignedTo
    )
  }
`;

export const CREATE_ISSUE = gql`
  mutation createIssue(
    $title: String!
    $issueType: Int
    $detail: String
    $richDetail: String
    $resolution: String
    $priority: Int
    $status: Int
    $dateEntered: String
    $dateDue: String
    $dateComplete: String
    $enteredBy: Int
    $assignedTo: Int
  ) {
    createIssue(
      title: $title
      issueType: $issueType
      detail: $detail
      richDetail: $richDetail
      resolution: $resolution
      priority: $priority
      status: $status
      dateEntered: $dateEntered
      dateDue: $dateDue
      dateComplete: $dateComplete
      enteredBy: $enteredBy
      assignedTo: $assignedTo
    ) {
      _id
    }
  }
`;

export const CREATE_LAB_LOCATION = gql`
  mutation createLabLocation(
    $abId: ID!
    $labId: ID!
    $locationName: String!
    $address1: String
    $address2: String
    $city: String
    $state: String
    $zip: String
    $county: String
    $country: String
    $attention: String
  ) {
    createLabLocation(
      abId: $abId
      labId: $labId
      locationName: $locationName
      address1: $address1
      address2: $address2
      city: $city
      state: $state
      zip: $zip
      county: $county
      country: $country
      attention: $attention
    ) {
      _id
    }
  }
`;

export const UPDATE_LAB_LOCATION = gql`
  mutation updateLabLocation(
    $id: ID!
    $abId: ID!
    $locationName: String!
    $address1: String
    $address2: String
    $city: String
    $state: String
    $zip: String
    $county: String
    $country: String
    $lat: String
    $lng: String
    $attention: String
  ) {
    updateLabLocation(
      id: $id
      abId: $abId
      locationName: $locationName
      address1: $address1
      address2: $address2
      city: $city
      state: $state
      zip: $zip
      county: $county
      country: $country
      lat: $lat
      lng: $lng
      attention: $attention
    )
  }
`;

export const REMOVE_LAB_LOCATION = gql`
  mutation removeLabLocation($_id: ID!) {
    removeLabLocation(_id: $_id)
  }
`;

export const REMOVE_LAB_CONTACT = gql`
  mutation removeLabContact($_id: ID!) {
    removeLabContact(_id: $_id)
  }
`;

export const UPDATE_LAB_CONTACT = gql`
  mutation updateLabContact(
    $id: ID!
    $abId: ID!
    $labId: ID!
    $lastName: String!
    $firstName: String!
    $email: String!
    $phone: String!
    $positionId: Int!
    $title: String!
    $primary: Boolean!
    $startDate: String
    $endDate: String
    $notes: String
    $active: Boolean
  ) {
    updateLabContact(
      id: $id
      abId: $abId
      labId: $labId
      lastName: $lastName
      firstName: $firstName
      email: $email
      phone: $phone
      positionId: $positionId
      title: $title
      primary: $primary
      startDate: $startDate
      endDate: $endDate
      notes: $notes
      active: $active
    )
  }
`;

export const CREATE_LAB_CONTACT = gql`
  mutation createLabContact(
    $abId: ID!
    $labId: ID!
    $lastName: String!
    $firstName: String!
    $email: String!
    $phone: String!
    $positionId: Int!
    $title: String!
    $startDate: String
    $endDate: String
    $notes: String
    $primary: Boolean!
    $active: Boolean
  ) {
    createLabContact(
      abId: $abId
      labId: $labId
      lastName: $lastName
      firstName: $firstName
      email: $email
      phone: $phone
      positionId: $positionId
      title: $title
      primary: $primary
      startDate: $startDate
      endDate: $endDate
      notes: $notes
      active: $active
    ) {
      _id
    }
  }
`;

export const CREATE_KEY_POSITION = gql`
  mutation createKeyPosition(
    $abId: ID!
    $labId: ID!
    $positionId: ID!
    $contactId: ID!
    $startDate: String
    $endDate: String
    $notes: String
  ) {
    createKeyPosition(
      abId: $abId
      labId: $labId
      positionId: $positionId
      contactId: $contactId
      startDate: $startDate
      endDate: $endDate
      notes: $notes
    ) {
      _id
    }
  }
`;

export const UPDATE_KEY_POSITION = gql`
  mutation updateKeyPosition(
    $id: ID!
    $abId: ID!
    $labId: ID!
    $positionId: ID!
    $contactId: ID!
    $startDate: String
    $endDate: String
    $notes: String
  ) {
    updateKeyPosition(
      id: $id
      abId: $abId
      labId: $labId
      positionId: $positionId
      contactId: $contactId
      startDate: $startDate
      endDate: $endDate
      notes: $notes
    )
  }
`;

export const REMOVE_KEY_POSITION = gql`
  mutation removeKeyPosition($id: ID!, $abId: ID!, $labId: ID!) {
    removeKeyPosition(id: $id, abId: $abId, labId: $labId)
  }
`;

export const REMOVE_ABPARAMETERS = gql`
  mutation removeAbParameters($abId: ID!, $_idArr: [ID!]!) {
    removeAbParameters(abId: $abId, _idArr: $_idArr)
  }
`;

export const CREATE_SNAPSHOT = gql`
  mutation createSnapshot(
    $abId: ID!
    $labId: ID!
    $locationId: ID!
    $notes: String!
    $createDate: String!
    $createdBy: Int!
    $issueDate: String
    $issuedBy: String
  ) {
    createSnapshot(
      input: {
        header: {
          abId: $abId
          labId: $labId
          locationId: $locationId
          notes: $notes
          createDate: $createDate
          createdBy: $createdBy
          issueDate: $issueDate
          issuedBy: $issuedBy
        }
      }
    )
  }
`;

export const DELETE_SNAPSHOT = gql`
  mutation deleteSnapshot($abId: ID!, $labId: ID!, $snapshotId: ID!) {
    deleteSnapshot(
      input: { abId: $abId, labId: $labId, snapshotId: $snapshotId }
    )
  }
`;
export const ADD_SELECTED_INVOICE_PARAMETERS = gql`
  mutation addSelectedInvoiceParmeters(
    $abId: ID!
    $labId: ID!
    $invoiceId: ID!
    $invoiceParams: [ID!]!
  ) {
    addSelectedInvoiceParmeters(
      abId: $abId
      labId: $labId
      invoiceId: $invoiceId
      invoiceParams: $invoiceParams
    )
  }
`;

export const ADD_ALL_INVOICE_PARAMETERS = gql`
  mutation addAllInvoiceParmeters($abId: ID!, $labId: ID!, $invoiceId: ID!) {
    addAllInvoiceParmeters(abId: $abId, labId: $labId, invoiceId: $invoiceId)
  }
`;

export const REMOVE_ALL_INVOICE_PARAMETERS = gql`
  mutation removeAllInvoiceParmeters($abId: ID!, $labId: ID!, $invoiceId: ID!) {
    removeAllInvoiceParmeters(abId: $abId, labId: $labId, invoiceId: $invoiceId)
  }
`;

export const REMOVE_INVOICE_PARAMETER = gql`
  mutation removeInvoiceParmeter(
    $abId: ID!
    $labId: ID!
    $invoiceId: ID!
    $abParamId: ID!
  ) {
    removeInvoiceParmeter(
      abId: $abId
      labId: $labId
      invoiceId: $invoiceId
      abParamId: $abParamId
    )
  }
`;

export const ADD_INVOICE_PARAMETER = gql`
  mutation addInvoiceParmeter(
    $abId: ID!
    $labId: ID!
    $invoiceId: ID!
    $abParamId: ID!
  ) {
    addInvoiceParmeter(
      abId: $abId
      labId: $labId
      invoiceId: $invoiceId
      abParamId: $abParamId
    )
  }
`;

export const CREATE_INVOICE = gql`
  mutation createInvoice(
    $abId: ID!
    $labId: ID!
    $description: String
    $issueDate: String!
    $dueDate: String!
    $months: Int!
    $issuedBy: ID!
  ) {
    createInvoice(
      abId: $abId
      labId: $labId
      description: $description
      issueDate: $issueDate
      dueDate: $dueDate
      months: $months
      issuedBy: $issuedBy
    )
  }
`;

export const UPDATE_INVOICE_FIELD = gql`
  mutation updateInvoiceField(
    $abId: ID!
    $labId: ID!
    $invoiceId: ID!
    $field: String!
    $strValue: String
  ) {
    updateInvoiceField(
      abId: $abId
      labId: $labId
      invoiceId: $invoiceId
      field: $field
      strValue: $strValue
    )
  }
`;

export const REMOVE_INVOICE = gql`
  mutation removeInvoice($abId: ID!, $labId: ID!, $_id: ID!) {
    removeInvoice(abId: $abId, labId: $labId, _id: $_id)
  }
`;

export const ADD_INVOICE_FEE = gql`
  mutation addInvoiceFee(
    $abId: ID!
    $labId: ID!
    $invoiceId: ID!
    $feeId: ID
    $description: String!
    $amount: Float!
    $saveFlag: Boolean!
  ) {
    addInvoiceFee(
      abId: $abId
      labId: $labId
      invoiceId: $invoiceId
      feeId: $feeId
      description: $description
      amount: $amount
      saveFlag: $saveFlag
    )
  }
`;

export const REMOVE_INVOICE_FEE = gql`
  mutation removeInvoiceFee(
    $abId: ID!
    $labId: ID!
    $invoiceId: ID!
    $feeId: ID!
  ) {
    removeInvoiceFee(
      abId: $abId
      labId: $labId
      invoiceId: $invoiceId
      feeId: $feeId
    )
  }
`;

export const COMPLETE_INVOICE = gql`
  mutation completeInvoice(
    $abId: ID!
    $labId: ID!
    $invoiceId: ID!
    $totalAmount: Float!
    $invoiceMethods: [InvoiceMethodWs!]!
    $invoiceAnalytes: [InvoiceMethodWs!]!
  ) {
    completeInvoice(
      abId: $abId
      labId: $labId
      invoiceId: $invoiceId
      totalAmount: $totalAmount
      invoiceMethods: $invoiceMethods
      invoiceAnalytes: $invoiceAnalytes
    )
  }
`;

export const REMOVE_INVOICE_PDF = gql`
  mutation removeInvoicePdf($abId: ID!, $labId: ID!, $invoiceId: ID!) {
    removeInvoicePdf(abId: $abId, labId: $labId, invoiceId: $invoiceId)
  }
`;

export const ADD_INVOICE_FEE_CONFIG = gql`
  mutation addInvoiceFeeConfig(
    $abId: ID!
    $description: String!
    $amount: Float!
    $defaultFee: Boolean!
  ) {
    addInvoiceFeeConfig(
      abId: $abId
      description: $description
      amount: $amount
      defaultFee: $defaultFee
    )
  }
`;

export const UPDATE_INVOICE_FEE_CONFIG = gql`
  mutation updateInvoiceFeeConfig(
    $abId: ID!
    $configFeeId: ID!
    $description: String!
    $amount: Float!
    $defaultFee: Boolean!
  ) {
    updateInvoiceFeeConfig(
      abId: $abId
      configFeeId: $configFeeId
      description: $description
      amount: $amount
      defaultFee: $defaultFee
    )
  }
`;

export const REMOVE_INVOICE_FEE_CONFIG = gql`
  mutation removeInvoiceFeeConfig($abId: ID!, $configFeeId: ID!) {
    removeInvoiceFeeConfig(abId: $abId, configFeeId: $configFeeId)
  }
`;

export const CREATE_INVOICE_PDF = gql`
  mutation createInvoicePdf(
    $abId: ID!
    $labId: ID!
    $invoiceId: ID!
    $base64pdf: String!
  ) {
    createInvoicePdf(
      abId: $abId
      labId: $labId
      invoiceId: $invoiceId
      base64pdf: $base64pdf
    )
  }
`;

export const INVOICE_RPT_M = gql`
  mutation invoiceRptM($abId: ID!, $labId: ID!, $invoiceId: ID) {
    invoiceRptM(abId: $abId, labId: $labId, invoiceId: $invoiceId) {
      invoiceNumber
      description
      issueDate
      dueDate
      labIdNo
      labName
      epaNumber
      accountNumber
      billingAdd1
      billingAdd2
      billingState
      billingCity
      billingZip
      attention
      pcFirst
      pcLast
      adminFees {
        description
        amount
      }
      methodFees {
        feeName
        subTotal
        proratedAmt
        count
      }
      analyteFees {
        feeName
        subTotal
        proratedAmt
        count
      }
      rptDef {
        text1Name
        text1Json
        text2Name
        text2Json
        image1Name
        image1Json
        image1Data
        image2Name
        image2Json
        image2Data
      }
    }
  }
`;

export const UPDATE_LABPARAMETER = gql`
  mutation updateLabParameter(
    $id: ID!
    $primaryAb: Int!
    $startDate: String!
    $endDate: String!
    $notes: String!
    $approved: Int!
  ) {
    updateLabParameter(
      id: $id
      primaryAb: $primaryAb
      startDate: $startDate
      endDate: $endDate
      notes: $notes
      approved: $approved
    )
  }
`;

export const UPDATE_LABPARAMETERS = gql`
  mutation updateLabParameters(
    $abId: ID!
    $labId: ID!
    $startDate: String!
    $primaryAb: ID!
    $notes: String!
    $approved: Int!
    $startDateChk: Boolean!
    $primaryAbChk: Boolean!
    $notesChk: Boolean!
    $approvedChk: Boolean!
    $_id: [ID!]!
  ) {
    updateLabParameters(
      abId: $abId
      labId: $labId
      startDate: $startDate
      primaryAb: $primaryAb
      notes: $notes
      approved: $approved
      startDateChk: $startDateChk
      primaryAbChk: $primaryAbChk
      notesChk: $notesChk
      approvedChk: $approvedChk
      _id: $_id
    )
  }
`;

export const REMOVE_LABPARAMETERS = gql`
  mutation removeLabParameters($abId: ID!, $labId: ID!, $_id: [ID!]!) {
    removeLabParameters(abId: $abId, labId: $labId, _id: $_id)
  }
`;

export const ADD_LABPARAMETERS = gql`
  mutation addLabParameters($abId: ID!, $labId: ID!, $abParamId: [ID!]!) {
    addLabParameters(abId: $abId, labId: $labId, abParamId: $abParamId)
  }
`;

export const UPDATE_METHODS = gql`
  mutation updateMethods(
    $abId: ID!
    $alias: String!
    $methodIds: [ID]!
    $templateId: Int!
    $isDefaultTemplate: Boolean
  ) {
    updateMethods(
      abId: $abId
      alias: $alias
      methodIds: $methodIds
      templateId: $templateId
      isDefaultTemplate: $isDefaultTemplate
    )
  }
`;

export const REMOVE_PT_RESULTS = gql`
  mutation removePtResults($abId: ID!, $_id: [ID!]!) {
    removePtResults(abId: $abId, _id: $_id)
  }
`;

export const UPDATE_PT_RESULTS = gql`
  mutation updatePtResults($abId: ID!, $updateDate: String!, $notes: String!, $activeResult: Boolean!, $_id: [ID!]!) {
    updatePtResults(abId: $abId, updateDate: $updateDate, notes: $notes, activeResult: $activeResult, _id: $_id)
  }
`;

export const CREATE_APPROVED_XLAT = gql`
  mutation createAprovedXlat(
    $abId: ID!
    $approvedText: String!
    $approvedValue: Int!
  ) {
    createAprovedXlat(
      abId: $abId
      approvedText: $approvedText
      approvedValue: $approvedValue
    )
  }
`;

export const UPDATE_APPROVED_XLAT = gql`
  mutation updateAprovedXlat(
    $_id: ID!
    $abId: ID!
    $approvedText: String!
    $approvedValue: Int!
  ) {
    updateAprovedXlat(
      _id: $_id
      abId: $abId
      approvedText: $approvedText
      approvedValue: $approvedValue
    )
  }
`;

export const REMOVE_APPROVED_XLAT = gql`
  mutation removeAprovedXlat($abId: ID!, $_id: ID!) {
    removeAprovedXlat(abId: $abId, _id: $_id)
  }
`;

export const EXTERNAL_LINKS_CREATE = gql`
  mutation externalLinksCreate(
    $abId: ID!
    $title: String!
    $description: String
    $url: String!
    $sequence: Int!
  ) {
    externalLinksCreate(
      abId: $abId
      title: $title
      description: $description
      url: $url
      sequence: $sequence
    )
  }
`;

export const EXTERNAL_LINKS_UPDATE = gql`
  mutation externalLinksUpdate(
    $abId: ID!
    $_id: ID!
    $title: String!
    $description: String
    $url: String!
    $sequence: Int!
  ) {
    externalLinksUpdate(
      abId: $abId
      _id: $_id
      title: $title
      description: $description
      url: $url
      sequence: $sequence
    )
  }
`;

export const EXTERNAL_LINKS_DELETE = gql`
  mutation externalLinksDelete($abId: ID!, $_id: ID!) {
    externalLinksDelete(abId: $abId, _id: $_id)
  }
`;